.modal {
  width: 80%;
  height: 80%;
  padding: 1%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a8afb2;
    border-radius: 5px;
    height: 5px !important;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #92989b;
  }
  .modal-header {
    padding: 0;
  }
}
.moduleSelection {
  .pricingusersBlock {
    padding: 15px 0;
  }
  .userBlock label {
    background: none;
  }
  .rangeBlock {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #d1d1d4;
    .rangeBlockHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-weight: bold;
        color: #003f66;
        font-size: 14px;
      }
      .MuiInput-root {
        width: 60px;
        input[type="number"] {
          border: 1px solid #adc5d2;
          padding: 5px;
          border-radius: 5px;
          background: #f6fdff;
          color: #175073;
          font-weight: bold;
          text-align: center;
        }
        &::before {
          border-bottom: 0px solid red !important;
        }
      }
    }
    .rangeContainer {
      padding: 0;
      margin: 15px 10px 0;
      width: auto;
      .range {
        .MuiSlider-rail {
          overflow: hidden;
          -webkit-appearance: none;
          background-color: #e6e6e6;
          border-radius: 10px;
          opacity: 1;
          height: 15px;
          width: 103%;
          margin-left: -10px;
        }
        .MuiSlider-track {
          display: none;
        }
        .MuiSlider-thumb {
          background-color: #01314a;
          width: 15px;
          height: 15px;
          &::after,
          &::before {
            display: none;
          }
          &:hover {
            box-shadow: none;
          }
        }
        .Mui-focusVisible {
          box-shadow: none;
        }
      }
    }
  }
  .consoleFormBlock {
    h3 {
      margin-top: 0;
    }
  }
  .moduleCheckBoxBlock {
    border: 2px solid #dee7ec;
    border-radius: 10px;
    padding: 10px 15px;
    height: 40px;
    display: flex;
    align-items: center;
    .checkboxBlock {
      .iconBg {
        background: #40d4ff; // #e1f4fa;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        text-align: center;
        margin-right: 10px;
        img {
          width: 25px;
          height: 25px;
          margin: 0;
        }
      }
    }
  }
  #tickmarks {
    display: flex;
    justify-content: space-between;
    // padding: 0 10px;
  }

  #tickmarks p {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 1px;
    background: #d3d3d3;
    height: 10px;
    line-height: 40px;
    margin: 0 0 20px 0;
  }
}
.payslipPopupBlock {
  height: 90%;
  overflow-y: auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.actualPricing {
  background: #e5f5fd;
  border-radius: 20px;
  padding: 10px 0;
}
.updatedPricing {
  background: #e5f5fd;
  border-radius: 20px;
  padding: 10px 0;
  margin-top: 10px;
  .modal-header {
    margin: 20px;
  }
}
.modal-header {
  padding: 15px;
  display: block;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  label {
    font-weight: 900;
    color: #003f66;
    width: fit-content;
    padding: 0;
  }
  h2 {
    font-weight: bold;
    color: #003f66;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }
  span.smallText {
    font-size: 10px;
    font-weight: normal;
    top: 7px;
    position: absolute;
    margin-left: 5px;
  }
  .close:focus-visible,
  .close:focus {
    outline: none;
  }
}
.radioButtonSection {
  background: #e5f5fd;
  border-radius: 10px;
  margin: 0 0 20px 0 !important;
  padding: 5px;
  .radioContainer {
    label {
      margin-left: 5px;
      font-size: 13px;
    }
  }
}
.formBeforePayment {
  margin-left: 20px;
  .consoleFormContainer {
    padding: 0;
    h5 {
      margin-bottom: 0;
    }
  }
  .consoleFormBlock {
    background: #e5f5fd;
    border-radius: 20px;
  }
  h6 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 12px;
    border-width: 0;
  }
  .billingSection,
  .shippingSection {
    margin-top: 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #ffffff;//e5f5fd
    border:1px solid #ccebfa;
  }
  .checkboxContain{
    margin-top: 15px;
  }
}


.Invisible {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -9999;
}


.customizeViewContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}