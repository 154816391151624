.autoCompleteDropdownContainer {
  //   .MuiAutocomplete-endAdornment {
  //     -webkit-appearance: none !important;
  //     -moz-appearance: none !important;
  //     appearance: none !important; /* Remove default arrow */
  //     background-image: url(../images/dropdownArrow.png); /* Add custom arrow */
  //     background-repeat: no-repeat;
  //     background-position: calc(100% - 15px) calc(100% - 15px);
  //     background-size: 15px;
  //   }
  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #808080;
    font-family: "poppins_regular";
  }
  input {
    &::placeholder {
      color: #ccd5da;
      opacity: 1; /* Firefox */
      font-size: 16px;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccd5da;
      font-size: 16px;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccd5da;
      font-size: 16px;
    }
  }
  .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
    border: 1px solid #ccd6db;
    padding: 5px 5px;
    border-radius: 10px;
    margin-top: 5px;
    width: auto;
    display: flex;
  }
  .css-ghsjzk-MuiInputBase-root-MuiInput-root:before,
  .css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
    border: none;
  }
  .css-ghsjzk-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border: none;
  }
  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    border: 1px solid #ccd6db;
    //  padding: 3.5px 6px;
    border-radius: 10px;
    margin-top: 5px;
    width: auto;
    display: flex;
  }
  .MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root
    .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding: 0 !important;
    border: none;
  }
  // .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root{padding:n}
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .searchWrapper{border-radius: 10px;margin-top:5px;line-height:30px;}
}
