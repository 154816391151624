$primaryColor: #07addd; /*#16c1e2*/
$buttonPrimaryColor: #94ba33;
$buttonPrimaryTextColor: #ffffff;
/*Dashboard*/
$dashboardBlockHeading: #002c5b;
$dashboardBlockRadius: 25px;
$dashboardBlockBackgroundGrey: #f4f4f4;
$dashboardBlockSecondaryColor: #00a3d9;
body {
  background: #fbfbfb;
  font-family: "poppins_regular";
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a8afb2;
    border-radius: 5px;
    height: 5px !important;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #92989b;
  }
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
@font-face {
  font-family: poppins_regular;
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: poppins_bold;
  src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: Rubik-Bold;
  src: url(../fonts/Rubik-Bold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../fonts/Rubik-Regular.ttf);
}
body {
  margin: 0;
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none;
}
.mainContainer {
  //   display: flex;
  width: 100%;

  // .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  //   padding: 0;
  // }
  // .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  //   padding: 0;
  // }
  .MuiDrawer-paperAnchorLeft {
    z-index: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a8afb2;
      border-radius: 5px;
      height: 5px !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #92989b;
    }
  }
}
.mainContent {
  display: flex;
  width: 95%;
  // flex: 1;
  // padding: 20px 0 20px 20px;
  padding: 0;
}
.billingContent {
  display: flex;
  // flex: 1;
}
ul {
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}
#root {
  overflow: hidden;
}
.dashboardContent {
  width: 95%;
  display: block;
}
@media screen and (max-width: 1440px) {
  body {
    // border: 1px solid red;
    font-size: 12px !important;
    // border: 1px solid red;
  }
  .loginPatternOne {
    .loginContainer {
      padding: 25px 125px 0 !important;
      .loginBlock {
        p {
          padding: 0 40px !important;
          font-size: 13px !important;
          margin-bottom: 0 !important;
        }
      }
      .loginInput {
        input {
          font-size: 13px !important;
        }
      }
      .loginButton {
        margin-top: 40px !important;
      }
    }
  }
  .mainContent {
    width: 94% !important;
  }
  .MuiTableCell-root {
    font-size: 13px !important;
  }
  .invoiceSection {
    .billingDetailsHeader {
      h1,
      h2 {
        font-size: 12px !important;
      }
    }
  }
  .dashboardDataGrid {
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar {
      width: 9px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #a8afb2;
      border-radius: 20px;
      height: 6px;
      border: transparent;
    }
  }
  .dashboardGraphBlock {
    h1 {
      font-size: 12px !important;
    }
  }
  .lineChartContainer {
    padding: 15px !important;
    .lineChartHeader {
      .lineChartBlock {
        h1 {
          font-size: 11px !important;
        }
      }
    }
  }
}
.iconsBlock {
  margin: 5px 0px;
  img {
    height: 35px;
  }
  .MuiStepper-alternativeLabel {
    width: 35% !important;
    margin: 0px auto !important;
  }
}
.editableTableContainer {
  overflow-y: auto;
  margin-top: 15px;
  .gridContainer {
    .gridComponent {
      table {
        td {
          &:last-child{text-align: center!important;}
        }
      }
    }
  }
  .deleteDisplayButton {
    border-radius: 50%;
    background: #f9c3cd;
    width: 30px;
    height: 30px;
    padding: 5px;
    border: none;
    color:#fa234a;
  }
  .selectAndTextfield {
    width: 160px;
  }
  .inputBlockContainer {
    margin-top: 5px;
    border: 1px solid #ccd6db;
    border-radius: 10px !important;
    line-height: 40px;
    box-shadow: none;
    margin-right: 5px;
    width: 100%;
  }
  .consoleDatePicker {
    width: 160px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    scrollbar-width: thin;
  }
  &::-webkit-scrollbar {
    width: 9px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a8afb2;
    border-radius: 20px;
    height: 6px;
    border: transparent;
  }
}
@import "login.scss";
@import "leftSidebar.scss";
@import "innerNavigation.scss";
@import "Header.scss";
@import "FormElements.scss";
@import "./Dashboard";
@import "./Buttons.scss";
@import "./PlaceHolder.scss";
@import "./RadioButton.scss";
@import "./TabsNavigation.scss";
@import "./customDropdown.scss";
@import "./Checkbox.scss";
@import "./VideoGifContainer.scss";
@import "./Grid.scss";
@import "./UserManagementPopup.scss";
@import "./DashboardCard.scss";
@import "./lineChart.scss";
@import "./Popup.scss";
@import "./AutoCompleteSelect.scss";
@import "./ToggleButton.scss";
@import "./CommonPopup.scss";
@import "./ModuleSelection.scss";
@import "./PaymentConfirmation.scss";
@import "./invoiceTable.scss";
@import "./FormModel.scss"
