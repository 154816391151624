.dashboardContainer {
  display: flex;
  padding: 10px;
}
.consoleContainer {
  // flex: 100%;
  // width:80%;
  padding: 0 20px;
}
.dashBoardButton {
  background-color: #94cd00 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  text-transform: none !important;
  font-family: "poppins_regular";
  font-size: 13px;
  margin: 0 10px 0 0;
  border: 1px solid transparent !important;
  padding: 10px 30px;
  text-decoration: none !important;
}
.dashBoardButton:hover {
  background-color: #ffffff !important;
  border: 1px solid #94cd00 !important;
  box-shadow: none !important;
  border-radius: 10px;
  color: #94cd00 !important;
}

/**Dashboard Header**/
.dashboardHeader {
  padding: 10px;
}
.dashboardHeaderContainer {
  background-color: transparent !important;
  box-shadow: none !important;
  .billingButton {
    background-color: #c9e2ec;
    box-shadow: none;
    border-radius: 10px;
    color: #007fad;
    text-transform: none;
    font-family: "poppins_regular";
    font-weight: bold;
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
  }
  .MuiContainer-root {
    padding: 0 10px 20px 0;
  }
  .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #ffffff !important;
    border: 1px solid #007fad;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad;
  }
}

.consoleHeadingText {
  font-family: "poppins_bold";
  color: #004b73;
  margin: 0;
}
.headerRightBlock {
  display: flex;
  justify-content: end;
  // .css-2uchni {
  //   height: 37px;
  // }
  .headerRightContent {
    display: flex;
    .greenButton {
      margin-right: 20px;
    }
  }
}
.profileContainer {
  padding: 5px 0;
  width: 350px;
  .profileHeader {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
    padding: 0 10px 10px;
    h3 {
      margin: 0;
      font-size: 15px;
      color: #004b73;
    }
    h6 {
      font-size: 13px;
      color: #1f6184;
      margin: 5px 0 0;
      font-family: "poppins_regular";
      font-weight: 300;
    }
    .css-2s90m6-MuiAvatar-root {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
  .profileFooter {
    border-top: 1px solid #e5e5e5;
    padding: 10px 0 0;
    margin-top: 10px;
    p {
      display: flex;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .profileLinksBlock {
    .css-1uwgr7b-MuiTypography-root {
      font-size: 13px;
      color: #1f6184 !important;
      font-family: "poppins_regular";
    }
    a {
      color: #1f6184 !important;
      text-decoration: none;
    }
  }
}
// .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
//   border-radius: 10px !important;
// }
.dashboardCompanyContent {
  padding: 20px;
  border-radius: 10px !important;
  box-shadow: 0px 0px 15px -5px #999999 !important;
  background-color: #687cfe !important;
  .MuiCardContent-root:last-child {
    padding: 0 15px;
  }
}
.dashboardUserContent {
  padding: 20px;
  border-radius: 10px !important;
  box-shadow: 0px 0px 15px -5px #999999 !important;
  background-color: #ff7f5d !important;
  .MuiCardContent-root:last-child {
    padding: 0 15px;
  }
}
.dashboardCardCompanyIcon {
  display: flex;
  justify-content: center;
  background: #263bc7;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  img {
    margin: 10px 10px;
  }
}
.dashboardCardUserIcon {
  display: flex;
  justify-content: center;
  background: #d74b26;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  img {
    margin: 10px 10px;
  }
}
.dashboardCardHeading {
  color: #ffffff;
}
.dashboardCount {
  font-family: "poppins_bold" !important;
  font-size: 25px !important;
  color: #ffffff !important;
}
.MuiDataGrid-root {
  border: 1px solid transparent !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 0 solid transparent !important;
}
.MuiDataGrid-root .MuiDataGrid-row:hover,
.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background-color: none;
}
.MuiDataGrid-root .MuiDataGrid-row:nth-child(2n) {
  background-color: #ebfaff;
}
.MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
.dashboardDataGrid {
  width: 100%;
  height: 375px;
  margin-top: 20px;
  font-family: "poppins_regular";
  // border: 1px solid #c7d7df;
  border-radius: 10px;
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  .MuiDataGrid-root .MuiDataGrid-sortIcon,
  .MuiDataGrid-root .MuiDataGrid-filterIcon,
  .css-ptiqhd-MuiSvgIcon-root {
    color: #ffffff;
  }
  // .css-i4bv87-MuiSvgIcon-root {
  //   fill: #b6bab9;
  //   stroke: none;
  // }
  .MuiButtonBase-root-MuiCheckbox-root {
    color: #b6bab9;
  }
  .MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #004b73;
  }
}

// .css-1b34haf-MuiDataGrid-footerContainer {
//   display: none !important;
// }
.MuiDataGrid-columnHeaders {
  display: block !important;
  background-color: #007fad;
  border-radius: 10px 10px 0 0;
  color: #ffffff;
}
// .css-204u17-MuiDataGrid-main {
//   border-radius: 10px 10px 0 0 !important;
// }

.MuiTableCell-root {
  border-bottom: 1px solid transparent !important;
  padding: 5px 20px !important;
  color: #668ba3 !important;
  font-size: 13px;
}
.invoiceSection {
  margin: 0 0 10px 10px;
  border-radius: 10px;
  background: #ffffff !important;
  box-shadow: 0px 0px 20px -8px #999999;
}

.invoiceSectionHeader {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
}
.invoiceSectionHeader h1 {
  margin: 0;
  padding: 20px 20px 10px;
  font-size: 15px;
  color: #004b73;
}
.invoiceSectionHeader h1 span {
  font-size: 13px;
  margin-left: 20px;
  font-weight: normal;
}
.invoiceSectionHeader span {
  font-size: 13px;
  margin-left: 20px;
  font-weight: normal;
  color: #004b73;
}
.invoiceSection h5 {
  margin: 10px 20px;
  color: #003f66;
}
.invoiceSectionFooter {
  margin-bottom: 10px;
  padding-bottom: 10px;
  h5 {
    margin: 0;
    padding: 20px 20px 10px;
    font-size: 15px;
    color: #004b73;
  }
  .greenButton,
  .greenBorderButton {
    width: 100%;
  }
}
.invoiceFooterContent {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.invoicePaidInfo {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.invoicePaidInfo span {
  font-size: 13px;
  font-weight: normal;
  color: #004b73;
}
.invoiceFooter {
  padding: 20px;
}
.invoiceFooter h1 {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0px;
  font-size: 15px;
  color: #004b73;
}
.invoiceNextDueIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
/**Addon Section**/
.addonSection {
  // padding: 10px 20px;
  margin: 10px 0 10px 10px;
  background: #ffffff !important;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -8px #999999;
}
.addonSection {
  .headingText {
    margin: 0 0 10px;
    padding: 15px 20px;
    font-size: 15px;
    color: #004b73;
    border-bottom: 1px solid #ccdbe3;
  }
  li span {
    font-family: "poppins_regular";
    font-weight: bold;
  }
  .statusGreen span {
    background: #e9f5cc;
    color: #94cd00;
    padding: 5px 10px;
    border-radius: 20px;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
  }
  .iconSize {
    height: 30px;
  }
}
.dashboardGraphBlock {
  margin-top: 30px;
  h1 {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0px;
    font-size: 18px;
    color: #004b73;
  }
}
