.invoiceTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
  td {
    padding: 5px;
    border: 0.5px solid #d9d9d9;
    span{
        font-weight: bold;
    }
  }
  .tableHeading {
    td {
      font-weight: bold;
    }
  }
}
