.statusCompleted {
  background-color: #23aafa;
  border-radius: 20px;
  padding: 3px 20px;
  color: #ffffff;
  font-size: 13px;
}
.paymentDue {
  background-color: #fa234a;
  border-radius: 20px;
  padding: 3px 20px;
  color: #ffffff;
  font-size: 13px;
}
.gridContainer {
  position: relative;
  .gridComponent {
    // position: absolute;
    // padding-top: 40px;
    .gridButtonBlock {
      display: flex;
      justify-content: flex-end;
      .button {
        margin: 0 0 0 15px;
      }
    }
    table {      
      border-collapse: collapse;
      font-size: 14px;
      table-layout: auto;
      width: 100%;
    }
    table th {
      background: #007fad;
      padding: 12px 10px;
      // width: 80%;
      color: #ffffff;
      font-weight: 100;
      border-right: 0.5px solid #c7d7df;
      text-align: left;
      overflow:hidden; white-space:nowrap
    }
    table th input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
    }
    table th input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table th input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 38%;
      height: 45%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th input[type="checkbox"]:checked:after {
      position: absolute;
      left: -2px;
      bottom: 5px;
      height: 2px;
      width: 65%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th:first-child {
      border-radius: 10px 0 0 0;
      // border-right: none;
    }

    table th:last-child {
      border-radius: 0 10px 0 0;
      border-right: none;
    }
    table tr:nth-child(even) {
      background-color: #ebfaff;
    }
    table tr:nth-child(odd) {
      background-color: #ffffff;
    }
    table td {
      padding: 12px 10px 5px;
      border-right: 0.5px solid #d0e0e7;
      color: #969c99;
      overflow:hidden; white-space:nowrap
      
    }
    // table td:first-child {
    //   border-right: none;
    // }
    table td:last-child {
      border-right: none;
      text-align: right !important;
    }
    table td.react-bs-table-no-data{text-align: center!important;}
    table td input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
    }
    table td input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table td input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 38%;
      height: 45%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table td input[type="checkbox"]:checked:after {
      position: absolute;
      left: -2px;
      bottom: 5px;
      height: 2px;
      width: 65%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table svg {
      width: 20px;
      height: 20px;
      margin: 0 10px;
      cursor: pointer;
    }
    .buttonBlock {
      display: flex;
      justify-content: end;
      .search-label span {
        display: none;
      }
      #search-bar-0 {
        padding: 10px;
        border: 1px solid #b0b0b0;
        border-radius: 10px;
        width: 90%;
      }
    }
    .react-bootstrap-table {
      margin-top: 10px;
      overflow: auto;
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        scrollbar-width: thin;
      }
      &::-webkit-scrollbar {
        width: 9px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #a8afb2;
        border-radius: 20px;
        height: 6px;
        border: transparent;
      }
    }
    //   .react-bs-table-sizePerPage-dropdown {
    //     display: none;
    //   }
    .pagination {
      display: flex;
      justify-content: end;
      .active.page-item {
        padding: 1px 10px;
        background: #e2e9ed;
        margin: 0 10px 0 0;
        border-radius: 5px;
        a {
          color: #044e75;
        }
      }
      .page-item {
        padding: 1px 7px;
        margin: 0 10px 0 0;
        a {
          color: #a5bdcc;
          font-size: 13px;
        }
      }
    }
    .react-bs-table-sizePerPage-dropdown {
      display: block;
      float: left;
      #pageDropDown {
        display: none;
      }
      .dropdown-menu {
        display: flex;
        li {
          padding: 0 5px;
          border-radius: 5px;
          background: #d0e0e7;
          margin: 0 2px;
          width: 15px;
          line-height: 20px;
          text-align: center;
          a {
            color: #044e75;
            font-size: 11px;
          }
        }
      }
    }
  }
}

.userGridContainer {
  position: relative;
  .userGridComponent {
    // position: absolute;
    padding-top: 20px;
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
    }
    // table tr {
    //   border-bottom: 1px solid #d0e0e7;
    // }
    table th {
      background: #007fad;
      padding: 15px 10px;
      color: #ffffff;
      font-weight: 100;
      border-right: 1px solid #c7d7df;
      text-align: left;
    }
    table th.tableHeadBlock {
      text-align: center !important;
    }
    table th input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
    }
    table th input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table th input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 38%;
      height: 45%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th input[type="checkbox"]:checked:after {
      position: absolute;
      left: -2px;
      bottom: 5px;
      height: 2px;
      width: 65%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table th:first-child {
      border-radius: 10px 0 0 0;
      // border-right: none;
    }

    table th:last-child {
      border-radius: 0 10px 0 0;
      border-right: none;
    }
    table td {
      padding: 12px 10px 5px;
      border-right: 1px solid #d0e0e7;
      color: #969c99;
    }
    table td.tableHeadBlock {
      text-align: center !important;
    }
    table td:last-child {
      border-right: none;
    }
    table td input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #acc4d1;
      padding: 9px;
      border-radius: 5px;
      display: inline-block;
      position: relative;
    }
    table td input[type="checkbox"]:checked {
      background-color: #004b73;
      border: 2px solid #004b73;
      color: #ffffff;
    }
    table td input[type="checkbox"]:checked:before {
      position: absolute;
      left: -3px;
      top: 38%;
      height: 45%;
      width: 2px;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table td input[type="checkbox"]:checked:after {
      position: absolute;
      left: -2px;
      bottom: 5px;
      height: 2px;
      width: 65%;
      background-color: #ffffff;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    table td button {
      width: 25px;
      height: 25px;
      margin-right: 15px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    // table {
    //   .userGridSecondTable {
    //     width: 100%;
    //     display: contents;
    //     table tr:nth-child(even) {
    //       background-color: #ebfaff;
    //     }
    //     table tr:nth-child(odd) {
    //       background-color: #fff;
    //     }
    //   }
    // }

    .react-bootstrap-table {
      margin-top: 10px;
    }
    .react-bs-table-sizePerPage-dropdown {
      display: none;
    }
    .pagination {
      display: flex;
      justify-content: end;
      .active.page-item {
        padding: 1px 10px;
        background: #e2e9ed;
        margin: 0 10px 0 0;
        border-radius: 5px;
        a {
          color: #044e75;
        }
      }
      .page-item {
        padding: 1px 7px;
        margin: 0 10px 0 0;
        a {
          color: #a5bdcc;
          font-size: 13px;
        }
      }
    }
  }
}
.addonSection {
  .headingText {
    margin: 0 0 10px;
    padding: 15px 20px;
    font-size: 15px;
    color: #004b73;
    border-bottom: 1px solid #ccdbe3;
  }
  li span {
    font-family: "poppins_regular";
    font-weight: bold;
  }
  .statusGreen span {
    background: #e9f5cc;
    color: #94cd00;
    padding: 5px 10px;
    border-radius: 20px;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
  }
  .iconSize {
    height: 30px;
  }
}
.dashboardGraphBlock {
  margin-top: 30px;
  h1 {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0px;
    font-size: 18px;
    color: #004b73;
  }
}
.invoiceSection .billingDetailsHeader {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.invoiceSection .billingDetailsHeader h1 {
  font-size: 15px;
  color: #4d7894;
  font-weight: normal;
  margin: 0;
}
.invoiceSection .billingDetailsHeader h2 {
  font-size: 15px;
  color: #003f66;
  margin-bottom: 0;
}
.levelContainer {
  display: flex;
  align-items: center;
  .redStatus {
    height: 10px;
    width: 10px;
    background: #fa234a;
    border-radius: 20px;
    margin-right: 10px;
  }
  .orangeStatus {
    height: 10px;
    width: 10px;
    background: #f5980b;
    border-radius: 20px;
    margin-right: 10px;
  }
  .blueStatus {
    height: 10px;
    width: 10px;
    background: #9b94ff;
    border-radius: 20px;
    margin-right: 10px;
  }
  .greenStatus {
    height: 10px;
    width: 10px;
    background: #a0de00;
    border-radius: 20px;
    margin-right: 10px;
  }
  .lightBlueStatus {
    height: 10px;
    width: 10px;
    background: #40d4ff;
    border-radius: 20px;
    margin-right: 10px;
  }
}
.approvalHierarcyBlock {

//   // .autoCompleteDropdownContainer{
//   //   border:1px solid #ccd6db;
//   //   border-radius:10px;
//   // }
//   overflow: hidden;
  .multiSelectContainer {
    border: 1px solid #ccd6db;
    border-radius: 10px;
//     // overflow: visible;
  }
//   .searchWrapper {
//     border: none;
//   }
  .optionListContainer {
    width: auto;
    // display: block;
    top: auto;
  }
  .MuiDataGrid-row {
    display: flex;
    align-items: center;
    min-height: 110px !important;
  
  }
  .MuiDataGrid-cell { 
    max-height: 250px !important;
    white-space: inherit!important;
    overflow: visible!important;
    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: 0 !important;
      border: none;
    }
  }
//   .multiSelectContainer {
//     width: 235px;
//     // height: 55px;
//     // overflow-x: auto;
    
//     position: inherit;
//     // border:1px solid red;
//     // border-radius: 10px;
//     .chip {
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       display: block;
//       position: relative;
//     }
//     .closeIcon {
//       position: absolute;
//       top: 7px;
//       right: 10px;
//     }
  // }

  .MuiOutlinedInput-root {
    padding: 10px !important;
    border-radius: 10px;
  }
  .MuiTablePagination-root {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 6px;
  }

  /* Track */
  // ::-webkit-scrollbar-track {
  //   background: #f1f1f1;
  //   margin:6px 0;
  // }

  /* Handle */
  ::-webkit-scrollbar-thumb {
     background: #888;
     border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #555;
  // }
  
}
