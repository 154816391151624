@use "./style.scss";

.loginPatternOne {
  display: flex;
  .rightContent {
    padding-top: 200px;
    height: 100vh;
    color: #ffffff;
    text-align: center;
    background : {
      // image: url("../images/background.png");
      repeat: no-repeat;
      size: cover;
      attachment: inherit;
    }
  }
  .imageHolder {
    h6 {
      color: #ffffff;
    }
    .content {
      width: 600px;
      margin: 0 auto;
    }
  }
  .loginLeftBackground {
    position: fixed;
    height: 100vh;
    z-index: -1;
  }
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
  .loginContainer {
    padding: 80px 150px 0px;
    .loginBlock {
      margin: 0 auto;
      // max-width: 320px;
      img {
        width: 125px;
      }
      h1 {
        color: $primaryColor;
        text-align: center;
        font-family: "poppins_bold";
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
      p {
        color: #cfcfcf;
        text-align: center;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 25px;
        padding: 0 70px;
      }
    }
    .loginInput {
      background: #f8f8f8 !important;
      border-left: 5px solid $primaryColor !important;
      border-radius: 0 !important;
      border-right: 1px solid #f8f8f8 !important;
      border-top: 1px solid #f8f8f8 !important;
      border-bottom: 1px solid #f8f8f8 !important;
      &:focus {
        box-shadow: none !important;
        border-left: 5px solid $primaryColor !important;
        border-right: 1px solid $primaryColor !important;
        border-top: 1px solid $primaryColor !important;
        border-bottom: 1px solid $primaryColor !important;
      }
      input {
        padding: 10px;
      }
      fieldset {
        border: none;
      }
    }
    .alreadyMember {
      color: $primaryColor;
      font-size: 13px;
      text-decoration: none;
      background: none;
      border: 0px;
      text-align: right;
    }
    .loginButton {
      width: 100%;
      border: 0px solid transparent !important;
      border-radius: 10px !important;
      background-color: #94cd00;
      // background-image: linear-gradient(75deg, #81b947, #003c60);
      padding: 10px;
      display: flex;
      justify-content: center;
      color: #ffffff;
      margin-top: 55px;
      text-decoration: none;
      font-weight: bold;
      text-transform: none;
      &:hover {
        background-color: #7aaa00;
      }
    }
    .keepSignedAndChechbox {
      // margin-top: 20px;
      padding: 10px;
      span {
        padding: 0px;
        font-size: 13px;
        color: #b3b3b3;
        margin-right: 5px;
      }
    }
    .css-i4bv87-MuiSvgIcon-root {
      color: #00abdc;
    }
    .keepSigned {
      font-size: 14px;
      color: #bbc3da;
      line-height: 24px;
    }
    .check {
      cursor: pointer;
      position: relative;
      margin: auto;
      width: 18px;
      height: 18px;
      -webkit-tap-highlight-color: transparent;
      transform: translate3d(0, 0, 0);
      margin-right: 5px;
      &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: -15px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(34, 50, 84, 0.03);
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      &:hover:before {
        opacity: 1;
      }
      &:hover svg {
        stroke: #b1cd6e;
      }
      svg {
        position: relative;
        z-index: 1;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: #c8ccd4;
        stroke-width: 1.5;
        transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;
        path {
          stroke-dasharray: 60;
          stroke-dashoffset: 0;
        }
        polyline {
          stroke-dasharray: 22;
          stroke-dashoffset: 66;
        }
      }
    }
    #cbx:checked + .check svg {
      stroke: #b1cd6e;
    }
    #cbx:checked + .check svg path {
      stroke-dashoffset: 60;
      transition: all 0.3s linear;
    }
    #cbx:checked + .check svg polyline {
      stroke-dashoffset: 42;
      transition: all 0.2s linear;
      transition-delay: 0.15s;
    }
  }
}
/********************************************************Login Pattern 2 *********************************************/
.loginBackground {
  background-image: url("../images/background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  border: 5px solid transparent;
  height: 100vh;
  .loginLeftContent {
    font-family: "Rubik-Regular";
    color: #ffffff;
    text-align: center;
    padding: 200px 130px 50px;

    h2 {
      font-weight: normal !important;
      margin: 0;
    }
    h1 {
      font-weight: normal !important;
      margin: 20px 0 40px;
      font-size: 75px;
    }
  }

  .Login {
    width: 100%;
  }
  .loginContainer {
    background: #ffffff;
    border: 1px solid #a6e3f0;
    margin: 5% auto 0 auto !important;
    box-shadow: 7px 7px 26px -10px;
    padding: 60px;
    width: 35%;
    h1 {
      color: #07addd;
      text-align: center;
      font-family: "poppins_bold";
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    p {
      color: #cfcfcf;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      margin-bottom: 25px;
    }
    input {
      padding: 10px;
    }
    fieldset {
      border: none;
    }
    .forgotPasswordButtons {
      width: 100%;
      button {
        width: 100%;
      }
    }

    .loginBlock {
      img {
        width: 55px;
      }

      h4 {
        color: $primaryColor;
        text-align: center;
      }
      p {
        color: #cfcfcf;
        text-align: center;
        line-height: 15px;
        font-size: 14px;
      }
    }
    .loginInput {
      background: #f8f8f8 !important;
      border-left: 5px solid $primaryColor !important;
      border-radius: 0 !important;
      border-right: 1px solid #f8f8f8 !important;
      border-top: 1px solid #f8f8f8 !important;
      border-bottom: 1px solid #f8f8f8 !important;
      &:focus {
        box-shadow: none !important;
        border-left: 5px solid $primaryColor !important;
        border-right: 1px solid $primaryColor !important;
        border-top: 1px solid $primaryColor !important;
        border-bottom: 1px solid $primaryColor !important;
      }
    }
    .alreadyMember {
      color: $primaryColor;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      background: none;
      border: 0px;
      padding: 0px;
    }
    .keepSigned {
      font-size: 14px;
      color: #bbc3da;
      line-height: 24px;
    }
    .check {
      cursor: pointer;
      position: relative;
      margin: auto;
      width: 18px;
      height: 18px;
      -webkit-tap-highlight-color: transparent;
      transform: translate3d(0, 0, 0);
      margin-right: 5px;
      &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: -15px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(34, 50, 84, 0.03);
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      &:hover:before {
        opacity: 1;
      }
      &:hover svg {
        stroke: #b1cd6e;
      }
      svg {
        position: relative;
        z-index: 1;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: #c8ccd4;
        stroke-width: 1.5;
        transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;
        path {
          stroke-dasharray: 60;
          stroke-dashoffset: 0;
        }
        polyline {
          stroke-dasharray: 22;
          stroke-dashoffset: 66;
        }
      }
    }
    #cbx:checked + .check svg {
      stroke: #b1cd6e;
    }
    #cbx:checked + .check svg path {
      stroke-dashoffset: 60;
      transition: all 0.3s linear;
    }
    #cbx:checked + .check svg polyline {
      stroke-dashoffset: 42;
      transition: all 0.2s linear;
      transition-delay: 0.15s;
    }
  }
  .confirmationBlock {
    text-align: center;
    h1 {
      color: #a0de00;
    }
    p {
      color: #14597e;
      padding: 0 20px;
    }
  }
}
/*Reset password*/

::-ms-reveal {
  display: none !important;
}
.loginContainer .loginBlock .resetPasswordFormValidation {
  width: 100%;
}
.loginContainer .loginBlock .resetPasswordTextField {
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.loginContainer .loginBlock .resetPasswordTextField .displayIcon {
  position: absolute;
  cursor: pointer;
  justify-content: space-between;
  top: 20px;
  right: 0px;
}
.loginContainer .loginBlock .resetPasswordErrorMessage {
  color: red;
  font-size: 13px;
}
