/*TextField*/

.consoleFormContainer {
  padding: 20px 0 0;
  h5 {
    font-family: "poppins_regular";
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #004b73;
  }
  h6 {
    font-family: "poppins_regular";
    font-size: 16px;
    padding: 15px 20px;
    margin: 0;
    color: #004b73;
    border-bottom: 1px solid #cccccc;
  }
  .consoleFormContent {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #d6d6d6;
  }
  .consoleFormBlock1 {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }
}
.consoleFormBlock2{
 width: 900px;
 margin-top: 5px;
}
.consoleFormBlock {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #d6d6d6;
  // width: 100%;
  // overflow: auto;
  .consoleTextFieldBlock {
    label {
      margin-bottom: 8px !important;
      font-size: 13px;
      color: #808080;
      font-family: "poppins_regular";
    }
  }
  .consoleAdormentBlock {
    
    label {
      margin-bottom: 8px;
      font-size: 13px;
      color: #808080;
      font-family: "poppins_regular";
    }
    .MuiFormControl-root {
      margin-top: 0;
      border: none;

      .MuiOutlinedInput-root {
        border-radius: 10px;
        height: 45px;
        border: 0px solid #e3e9ed;
        margin-top: 5px;
        width: -webkit-fill-available;
        color: #004b73;
        font-size: 15px;
        font-family: "poppins_regular";
        padding: 0 10px;
        
      }
      input {
        padding: 11px 10px;
        border-right: 1px solid #ccd6db;
        border-left: 1px solid #ccd6db;
      }
     
    }
  
    
  }
}
.TextFieldBlock {
  label {
    margin-bottom: 8px;
    font-size: 13px;
    color: #808080;
    font-family: "poppins_regular";

    .starcolor {
      color: #fa234a;
    }
  }
  .TextField {
    border-radius: 10px;
    height: 45px;
    border: 1px solid #ccd6db;
    margin-top: 5px;
    width: -webkit-fill-available;
    color: #004b73;
    font-size: 15px;
    font-family: "poppins_regular";
    padding: 0 10px;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: #ccd5da;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccd5da;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccd5da;
    }
  }
  .TextField:disabled{
    background: hsl(0, 0%, 95%);
  }
  .text-danger {
    color: #fa234a;
    font-size: 12px;
    padding-left: 10px;
  }
  .errorTextField {
    border: 1px solid #fa234a;
  }
}

//*Upload*//
.uploadBlock {
  .uploadBtn {
    padding: 31px 20px !important;
    border: 1px solid #ccd6db !important;
    border-radius: 10px !important;
    width: 100%;
    height: 165px;
    .uploadInfo {
      text-transform: none;
      text-align: center;
      color: #7f7f7f;
      font-family: "poppins_regular";
      h1 {
        margin: 0;
        font-weight: normal;
      }
      h3 {
        margin: 0;
        font-weight: normal;
        margin-bottom: 10px;
      }
      p {
        font-size: 11px;
        margin: 0px;
      }
    }
  }
  .imagePreview {
    border: 1px solid #ccd6db !important;
    border-radius: 10px !important;
    height: 165px;
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    .editIconStyle {
      position: absolute;
      width: 30px;
      height: 30px;
      padding: 0;
      //box-shadow: 0 0 10px 0;
      background: none;
      top: 5px;
      right: 5px;
      border: none;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}
/*DatePicker*/
.consoleDatePicker {
  border-radius: 10px;
  height: 25px;
  border: 1px solid #ccd6db;
  margin-top: 5px;
  display: flex !important;
  justify-content: space-between;
  padding: 10px;
  input {
    border: none;
    width: 100%;
    outline: none;
  }
  .MuiIconButton-edgeEnd {
    color: #bfccd2 !important;
  }
}
.consoleAdormentBlock {
  h5 {
    font-family: "poppins_regular";
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #004b73;
  }
  label {
    margin-bottom: 8px;
    font-size: 14px;
    color: #7f7f7f;
  }
  .MuiTextField-root {
    margin: 5px 0 0 0;
  }
  .MuiOutlinedInput-root {
    margin: 0;
    padding: 0;
    height: 46px;
    border-radius: 10px;
  }
  input {
    padding: 11px 10px;
    border-right: 1px solid #ccd6db;
  }
  button {
    text-transform: none;
    margin: 0 10px 0 0;
    padding: 0;
    color: #9fb1bb;
  }
}

/*Document Upload*/
.ducumentUploadBlock {
  display: flex;
  .hiddenInput {
    display: none;
  }
  .documentUpload {
    border: 1px dashed #94cd00;
    border-radius: 10px;
    margin-top: 5px;
    text-transform: none;
    color: #94cd00;
    height: 45px;
    background: transparent;
    box-shadow: none;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .uploadedInfo {
    display: flex;
    align-items: center;
    padding: 6px;
    margin: 5px 0px;
    border-radius: 10px;
    background: #f4f4f4;
    .tickIcon {
      width: 20px;
      height: 15px;
    }
    .selectedFileName {
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100px;
      white-space: nowrap;
      cursor: pointer;
    }
    .deleteButton {
      border: none;
      background: none;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}

/*Button Block*/
.consoleFormButtonBlock {
  padding: 20px 0 0;
  display: flex;
  justify-content: end;
  margin-bottom: 12px;
  button {
    margin-left: 10px;
  }
  .cancelButton {
    background-color: #ffffff !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #94cd00;
    text-transform: none;
    font-family: "poppins_regular";
    margin: 0 10px 0 0;
    font-size: 13px;
    border: 1px solid #94cd00;
  }
  .cancelButton:hover {
    background-color: #94cd00 !important;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    border: 1px solid transparent;
  }
  .saveButton {
    background-color: #94cd00;
    box-shadow: none;
    border-radius: 10px;
    color: #ffffff;
    text-transform: none;
    font-family: "poppins_regular";
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    padding: 5px 30px;
  }
  .saveButton:hover {
    background-color: #ffffff !important;
    border: 1px solid #94cd00;
    box-shadow: none !important;
    border-radius: 10px;
    color: #94cd00;
  }
}
/**/
.multiSelectDropdown {
  width: 100% !important;
  // .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  //   border: 2px solid #dee7ec !important;
  //   border-radius: 10px;
  //   legend {
  //     display: none;
  //   }
  // }
  .multiSelectContent {
    position: absolute !important;
    top: 14px;
    .iconSize {
      width: 40px;
      height: 40px;
      left: 55px;
      position: absolute;
      top: -8px;
    }
    .moduleName {
      position: absolute !important;
      top: 0;
      left: 110px;
      color: #004b73;
      font-family: "poppins_regular";
    }
    .mainCheckbox {
      position: absolute !important;
      top: 0;
      padding: 0;
      left: 20px;
      .css-i4bv87-MuiSvgIcon-root {
        fill: #004b73 !important;
        border-radius: 15px;
      }
    }
  }
  // .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  //   .MuiOutlinedInput-notchedOutline {
  //   border-color: #dee7ec;
  // }
  // .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  //   fill: #004b73 !important;
  // }
}
.multiSelectOption {
  // .css-i4bv87-MuiSvgIcon-root {
  //   fill: #004b73 !important;
  //   border-radius: 15px;
  // }
}
.roleAndCompanySelectionContainer {
  margin-bottom: 20px;

  .roleAndCompanySelection {
    display: flex;
    align-items: end;
    margin-bottom: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
    .buttonBlock {
      justify-content: center;
      display: flex;
      img {
        margin: 0;
      }
    }
  }
}
// dybnamic select red star

.mandatoryField {
  color: #f41919;
  display: inline;
  font-size: 13px;
}
/********Formula Table*****************/
.formulaTable {
  // overflow-y: auto;
  border: 1px solid #006286;
  border-radius: 10px 10px 0 0;
  // max-height: 415px;
  // min-height: 405px;
  // background-color: #007fad;
  tbody {
    display: block;
    min-height: 350px;
    max-height: 350px;
    overflow: auto;
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
      width: 9px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8afb2;
      border-radius: 20px;
      height: 6px;
      border: transparent;
    }
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    td {
      white-space: initial !important;
      overflow-wrap: break-word;
      .closeButtonForInput {
        align-items: center !important;
        height: inherit !important;
      }
    }
  }
  // thead {
  //   width: calc(100% - 8px);
  // }

  .formulaTableTbody {
    height: 200px;
    overflow-y: auto;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 9px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a8afb2;
    border-radius: 20px;
    height: 6px;
    border: transparent;
  }
}
.closeButtonForInput {
  display: flex !important;
  align-items: end !important;
  padding: 0 10px !important;
  width: 40px !important;
  height: 67px !important;
  svg {
    width: 20px;
    padding: 5px;
    height: 20px;
    border-radius: 20px;
    background: rgba(250, 35, 74, 0.1);
    fill: #fa234a;
    border: none;
    &:hover {
      fill: #fa234a;
      background: #f9c3cd;
    }
  }
  &:hover {
    background: none !important;
  }
}
.note {
  .informationText {
    margin: 0 15px;
    font-size: 13px;
    color: red;
  }
}
.formula {
  font-size: 13px;
  color: #004b73;
  span {
    word-break: break-word;
  }
}
.paySchemeCheckboxBlock{
  margin:15px 0;
}

.prResponseFilter {
  .consoleTextFieldBlock {
    margin-top: 10px!important;
  }
}
.filterBlockHeader {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c7d7df;
  width: 100%;
  color: #01314a;
  font-size: 20px;
  // font-family: "poppins_medium";
  svg {
    margin-right: 10px;
  }
}

.filterBlockContainer {
  padding: 0 20px;
  .consoleTextFieldBlock {
    margin-bottom: 10px;
  }
}

.draggable-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  background-color: #fff;
  cursor: move;
}

.sidebar-body {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 20px;
}

.consoleFormButtonBlock {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
