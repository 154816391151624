.leftSideBarContainer {
  background-color: #01314a;
  position: relative;
  padding: 15px;
  height: 100vh;
  .button {
    position: absolute !important;
    right: -12px;
    bottom: 35px;
  }
  li {
    list-style-type: none;
  }
  a {
    width: 25px;
    display: flex;
    color: #ffffff;
    text-decoration: none;
    img {
      width: 25px;
      margin: 10px 0;
      opacity: 0.5;
      border: 10px solid transparent;
      border-radius: 10px;
      &:hover {
        opacity: 1;
        background: #94cd00;
      }
    }
    &:hover {
      color: #ffffff;
      border-radius: 10px;
      opacity: 1;
    }
  }
  img.selected {
    background: #94cd00;
    opacity: 1;
  }
  .MuiSvgIcon-root {
    color: #fff;
  }
  .MuiIconButton-root {
    padding: 10px 15px 10px 10px !important;
  }
}
.NavLogoBlock {
  box-shadow: 0px 0px 15px #111111;
  padding: 10px 20px;
  background: #01314a;
  z-index: 1;
  img {
    background: #ffffff;
    padding: 5px;
    width: 25px;
    border-radius: 5px;
  }
}
.MuiDrawer-paper {
  background-color: transparent !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}
.navigationBlock {
  .MuiIconButton-edgeStart {
    background: #004b73 !important;
  }
  .makeStyles-appBarShift-1,
  .MuiIconButton-edgeStart {
    position: fixed !important;
    bottom: 35px !important;
    z-index: 2 !important;
    left: 65px;
  }

  .MuiDrawer-paper {
    z-index: 1 !important;
  }
}
.makeStyles-contentShift-4 {
  margin-left: 280px !important;
}
.makeStyles-drawerOpen-8 {
  height: 100vh !important;
  background: inherit !important;
}
// /* width */
// ::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background-color: transparent;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #00405a;
//   border-radius: 5px;
//   height: 5px !important;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #00405a;
// }
