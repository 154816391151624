.buttonsContainer {
  padding: 10px;
  display: flex;
}
.button {
  padding: 7px 20px;
  font-family: "poppins_regular" !important;
  text-transform: none !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  cursor: pointer;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.button.lightBlueButton {
  background-color: #c9e2ec !important;
  color: #007fad !important;
  font-weight: bold !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;
  &:hover {
    background-color: #99ccde !important;
    border: 1px solid #99ccde !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad;
  }
}
.button.greenButton {
  background-color: #94cd00 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;
  &:hover {
    background-color: #7eae02 !important;
    border: 1px solid #7eae02 !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.button.greenBorderButton {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  color: #94cd00 !important;
  font-size: 13px !important;
  border: 2px solid #94cd00 !important;
  &:hover {
    background-color: #f5fbe6 !important;
    border: 2px solid #94cd00 !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #94cd00 !important;
  }
}
.button.darkBlueButton {
  background-color: #004b73 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;
  &:hover {
    background-color: #003b5a !important;
    border: 1px solid #003b5a !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.button.deleteButton {
  background-color: #fa234a !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;
  &:hover {
    background-color: #d8193b !important;
    border: 1px solid #fa234a !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.button.editButton {
  background-color: #94cd00 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  border: 1px solid transparent !important;
  &:hover {
    background-color: #7eae02 !important;
    border: 1px solid #7eae02 !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #ffffff !important;
  }
}
.button.exportButton {
  background-color: #c9e2ec !important;
  color: #007fad !important;
  font-size: 13px !important;
  font-weight: bold;
  border: 1px solid transparent !important;
  &:hover {
    background-color: #99ccde !important;
    border: 1px solid #99ccde !important;
    box-shadow: none !important;
    border-radius: 10px;
    color: #007fad !important;
  }
}
.button.gridButton {
  background-color: #004b73 !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  margin: 0 10px !important;
  border: 1px solid transparent !important;
  padding: 10px !important;
  min-width: 0px !important;
  line-height: normal !important;
  width: 2%;
  height: 2%;
  position: absolute !important;
  right: 10px;
  &:hover {
    background-color: #004b73 !important;
    border: 1px solid #004b73 !important;
    box-shadow: none !important;
  }
  img {
    width: 7px;
    height: 9px;
  }
}
.button.gridPlusButton {
  background-color: #004b73 !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  margin: 0 10px !important;
  border: 1px solid transparent !important;
  padding: 10px !important;
  min-width: 0px !important;
  line-height: normal !important;
  width: 2%;
  height: 2%;
  &:hover {
    background-color: #004b73 !important;
    border: 1px solid #004b73 !important;
    box-shadow: none !important;
  }
  img {
    width: 10px;
    height: 9px;
  }
}

.button.disabled {
  background-color: #b2b7ad !important;
  opacity: 0.5;
  cursor: not-allowed !important;
}

.button.disabled:hover {
  background-color: #b2b7ad !important;
  border: none !important;
  box-shadow: none !important;
}
