.consoleHeader {
  padding: 10px;
}
.consoleHeaderContainer {
  background-color: transparent !important;
  box-shadow: none !important;
  .billingButton {
    background-color: #c9e2ec;
    box-shadow: none;
    border-radius: 10px;
    color: #007fad;
    text-transform: none;
    font-family: "poppins_regular";
    font-weight: bold;
    font-size: 13px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
  }
  .MuiContainer-root {
    padding: 0;
  }
}
.MuiButtonBase-root-MuiButton-root:hover {
  background-color: #ffffff !important;
  border: 1px solid #007fad;
  box-shadow: none !important;
  border-radius: 10px;
  color: #007fad;
}
.consoleHeadingText {
  font-family: "poppins_bold";
  color: #004b73;
  margin: 0;
 
}
.headerRightBlock {
  display: flex;
  justify-content: end;
  .css-2s90m6-MuiAvatar-root {
    background: #004b73;
  }
  .MuiButtonBase-root-MuiIconButton-root:hover {
    background: none;
  }
  .profileArrowIcon {
    width: 10px;
    margin-left: 10px;
  }
}
.profileContainer {
  padding: 5px 0;
  width: 350px;
  .profileHeader {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
    padding: 0 10px 10px;
    .css-2s90m6-MuiAvatar-root {
      background: #004b73;
    }
    h3 {
      margin: 0;
      font-size: 15px;
      color: #004b73;
    }
    h6 {
      font-size: 13px;
      color: #1f6184;
      margin: 5px 0 0;
      font-family: "poppins_regular";
      font-weight: 300;
    }

    .MuiAvatar-root {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
  .profileFooter {
    border-top: 1px solid #e5e5e5;
    padding: 10px 0 0;
    margin-top: 10px;
    p {
      display: flex;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .profileLinksBlock {
    .MuiTypography-root {
      font-size: 13px;
      color: #1f6184 !important;
      font-family: "poppins_regular";
    }
    a {
      color: #1f6184 !important;
      text-decoration: none;
    }
  }
}
.MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 10px !important;
}
.headerButtonBlock{display: flex;
a{margin-left: 10px;}
}
