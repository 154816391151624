/*Success Page*/
.successContainer {
  padding-top: 75px;
  display: flex;
  justify-content: center;
  .successCard {
    width: 40%;
    box-shadow: 0 0 15px -5px #c5c5cd;
    border-radius: 10px;
    margin:10px;
    .successCardHeader {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      padding: 20px;
      img {
        width: 10%;
        height: 10%;
        margin-right: 20px;
      }
      h2 {
        font-weight: bold;
        font-size: 20px;
        margin: 0 0 10px 0;
        color: #323347;
      }
      p {
        color: #5b5c6c;
        margin: 0;
        font-size: 13px;
      }
    }
    .successCardContainer {
      padding: 20px;
      h3 {
        font-size: 15px;
        font-weight: bold;
        color: #323347;
        margin:0 0 10px;
      }
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        list-style-type: none;
        line-height: 30px;
        color: #5b5c6c;
        font-size: 14px;
      }
    }
    .successButton {
      display: flex;
      justify-content: center;
      font-weight: bold;
      background: #a0de00;
      border-radius: 5px;
      padding: 10px 30px;
      border: none;
      color: #323347;
      font-size: 15px;
      margin-top: 15px;
      &:hover {
        background: #9ad700;
        color: #323347;
      }
    }
  }
}
