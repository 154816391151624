.tabsNavigationBlock {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: auto !important;
    padding: 0 !important;
    margin-right: 25px;
    font-family: "poppins_regular";
    text-transform: none;
    color: #6491a9;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #004b73;
    font-family: "poppins_bold";
    text-transform: none;
  }
  .css-1aquho2-MuiTabs-indicator {
    background-color: #94cd00;
    border-radius: 10px;
    height: 4px;
  }
}
