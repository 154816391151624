.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    height: 40%;
    width: 50%;
    overflow-y: auto;
    background: white;
    padding: 10px;
    border-radius: 8px;
    margin-left: 15%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
