.userManagementContainer {
  .closeIcon {
    position: absolute;
    border: none;
    top: 10px;
    right: 10px;
    background: none;
  }
  .closeIcon:hover {
    opacity: 0.3;
    cursor: pointer;
  }
  .userProfilePic {
    padding-top: 70px;
    justify-content: center;
  }
  .container-userProfilePic {
    border-radius: 120px;
    align-items: center;
  }
  .profilePic {
    width: 80px;
    border-radius: 50%;
    border: 2px solid #2e6b8c;
    padding: 1px;
  }
  h1 {
    font-weight: bold;
    font-size: 20px;
    color: #2e6b8c;
    font-family: "poppins_Regular";
    margin-bottom: 0px;
    margin-top: 7px;
  }
  .userProfileInfo {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .userProfileInfo .icon {
    justify-content: center;
    margin-right: 11px;
    width: 17px;
  }
  .userProfileInfo p {
    text-align: center;
    color: #2e6b8c;
    margin: 5px;
    font-size: 16px;
    font-family: "poppins_Regular";
  }
  .userProfileData {
    border-bottom: 1px solid #bbbbbb;
    padding-bottom: 17px;
  }
  .creatorInfo {
    display: flex;
    border-bottom: 1px solid #bbbbbb;
    text-align: left;
    justify-content: space-between;
    padding: 15px;
  }
  .creatorInfo div {
    padding: 0 20px;
    border-right: 1px solid #bbbbbb;
  }
  .creatorInfo h2 {
    color: #2e6b8c;
    font-size: 13px;
    margin-top: 0px;
    font-family: "poppins_Regular";
    margin-bottom: 5px;
  }
  .creatorInfo p {
    font-size: 13px;
    margin: 0px;
    color: #86a8bc;
    font-family: "poppins_Regular";
  }
  .noBorderRight {
    border-right: none !important;
  }

  .branchInfo {
    display: flex;
    border-bottom: 1px solid #bbbbbb;
    justify-content: left;
    padding: 17px 34px;
  }
  .branchInfo h1 {
    color: #2e6b8c;
    font-size: 13px;
    font-family: "poppins_Regular";
    margin: 0;
  }
  .branchInfo p {
    font-size: 13px;
    color: #92b1c3;
    align-items: center;
    padding-left: 8px;
    font-family: "poppins_Regular";
    margin: 0;
  }
  .buttonInfo {
    padding: 70px 25px 0;
  }
}
