
.toggleButton{
    display: flex;
    align-items:center;
    .buttonActive{
        color:#052137;
        margin-left:18px;
        font-size:13px;
    }
    .buttonInactive{
        color:#b0b0b0;
        margin-left:18px;
        font-size:13px;
    }
.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #48c80c;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #48c80c;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}