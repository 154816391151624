.placeHolderImage {
  width: 400px;
  margin: 40px 0;
}
.placeHolderHeading {
  font-family: "poppins_bold" !important;
  color: #004b73;
}
.placeHolderSubhead {
  font-family: "poppins_regular" !important;
  color: #4d819d;
}

.userPlaceholderBlock {
  background-image: url(../images/userPlaceHolderImage.png);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 350px 70px 50px !important;
}
.rolePlaceholderBlock {
  background-image: url(../images/rolePlaceHolderImage.png);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 350px 70px 50px !important;
}
