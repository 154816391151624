.dashboardDisplayCardsContainer {
  margin-top: 25px;
  .dashboardDisplayCards {
    .employeeDetails .employees .userprofilepic .profilePic {
      border-radius: 50%;
      width: 50px;
      padding: 6px;
    }
    .employeeDetails .employees .userprofilepic {
      border-radius: 120px;
      align-items: center;
      text-align: left;
    }
    .employeeDetails {
      text-align: left !important;
    }
    .employeeDetails h1 {
      font-family: "poppins_Regular";
      color: #134e72;
      font-size: 14px;
      justify-content: left;
      margin: 10px;
      text-align: left !important;
      padding-bottom: 5px;
    }
    .employeeDetails .buttonBlock {
      border: 2px dashed #d4eb99;
      border-radius: 50%;
      margin-left: 8px;
    }
    .employeeDetails .buttonBlock::hover {
      color: red !important;
    }
    .employeeDetails button {
      background: #d4eb99;
      border-radius: 50%;
      margin: 4px;
      width: 40px;
      height: 40px;
      color: #a0de00;
      font-weight: bold;
      border: none;
    }
    .employeeDetails a {
      color: #b2db47;
      font-family: "poppins_Regular";
      text-align: left !important;
      font-weight: bold;
      padding-left: 10px;
    }
    .css-1633um2-MuiPaper-root {
      text-align: left;
    }
    .employeeDetails .employees {
      display: flex;
      /* align-items: center; */
      margin: 0;
      align-items: center;
      padding-bottom: 15px;
    }
    .mailBlock .mailpic {
      width: 36px;
      align-items: center;
    }
    .mailBlock {
      display: flex;
      margin-bottom: 6px;
    }
    .profileInfo .mailBlock h1 {
      font-family: "poppins_Regular";
      font-size: 13px;
      color: #134e72;
      padding-left: 10px;
    }
    .border {
      display: flex;
      align-items: center;
      margin-left: 9px;
      margin-bottom: 7px;
    }
    .border .borderLeft {
      height: 27px;
      width: 4px;
      background: #a0de00;
      margin-top: 15px;
    }
    .border .addressBlock {
      margin-left: 20px;
    }
    .border .addressBlock h2 {
      font-family: "poppins_Regular";
      font-size: 15px;
      color: #134e72;
      margin-bottom: 4px;
      text-align: center;
    }
    .border .addressBlock p {
      font-family: "poppins_Regular";
      font-size: 12px;
      color: #134e72;
      margin: 0;
      text-align: center;
    }
    .tally .tallyDetails {
      display: flex;
    }
    .tally .tallyDetails h1 {
      font-family: "poppins_Regular";
      font-size: 13px;
      color: #134e72;
      padding-left: 10px;
    }
    .tally .tallyDetails .tallyIcon {
      width: 36px;
    }
    .tally .dataBlock {
      display: flex;
      justify-content: center;
      margin-top: 8px;
      padding: 10px;
      border-radius: 12px;
      background: #f5f5f5;
      align-items: center;
    }
    .tally .dataBlock p {
      font-family: "poppins_Regular";
      font-size: 12px;
      padding-left: 15px;
      color: #bbbbbb;
    }
    .tally {
      padding: 10px;
    }
    .css-1633um2-MuiPaper-root {
      padding: 15px;
    }
    .tally .dataBlock .dataIcon {
      width: 20px;
      align-items: center;
    }
  }
}
