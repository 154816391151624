.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modal {
  background-color: white;
  // text-align:center;
  border-radius: 10px;
  width: 40%;
  z-index: 0.5;
  padding:20px;
  .button{
    margin:0 5px;
  }
}
